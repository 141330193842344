import React from 'react'
import { Form } from "react-bootstrap"
import {Row, Col, Container,Breadcrumb } from 'react-bootstrap';


const CheckboxField = ({type}) => (
    <div class="custom-float">
        <label class="form-label form-label" for="validationtelephone">Would you also be interested in a {type === 'sell' ? 'Letting' : 'Selling'} valuation?</label>
        <div className="d-flex interest-radio">
            
        {type === 'sell' ?
        <>
            <label class="checkbox-div">No thanks, just sales
            <input type="checkbox" id="sell" checked name="sell" value="sell"  />
            <span class="checkmark"></span>
            </label>

            <label class="checkbox-div">Yes please
            <input type="checkbox" id="let" name="let" value="let"  />
            <span class="checkmark"></span>
            </label>
        </> :
        <>
            <label class="checkbox-div">No thanks, just let
            <input type="checkbox" id="let" checked name="let" value="let"  />
            <span class="checkmark"></span>
            </label>

            <label class="checkbox-div">Yes please
            <input type="checkbox" id="sell" name="sell" value="sell"  />
            <span class="checkmark"></span>
            </label>
        </>             
        }
        

        </div>
        <div className="interest-radio over-60s-valuation">
        <label class="checkbox-div">Over 60? Find out how you could boost your budget by up to 59%
        <input type="checkbox" id="over-60s" name="over-60s"/>
        <span class="checkmark"></span>
        </label>
        </div>
    </div>
);
    
export default CheckboxField