import React, { useRef, useState, useEffect } from "react"
import { Form, Button } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/select'
import MultiSelectField from './elements/multi-select'
import FileField from './elements/file'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import CustomRadio from './elements/custom-radio'
import { postFormData } from "./api/Api"
import $ from 'jquery/dist/jquery.min.js'
import { getIpAddress } from "../common/site/functions"
import { getAllCookie } from "../common/site/cookie";

// import "../../scss/forms.scss";

import axios from "axios"
import * as qs from "query-string"

function HomeVisitForm(props) {

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);
  const [selectedInterst, setSelectIntrest] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [finalresponse, setFinalResponse] = useState(false);
  // const [serverResponse, setServerResponse] = React.useState(``)
  const [ipAddress, setIpAddress] = useState("");
  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");
  const [file, setFile] = useState(""); // storing the uploaded file
  // storing the recived file from backend
  const [data, getFile] = useState({ name: "", path: "" });
  const [progress, setProgess] = useState(0); // progess bar
  const el = useRef(); // accesing input element

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();
  const fields = ([
    {
      element: "config",
      formname: "Book a Valuation",
      error_text: "Highlighted fields are required",
      success_text: "Thank you for your interest. A member of our team will contact you shortly.",
      email_temp_user: "valuation_user",
      email_temp_admin: "home_visit_valuation_admin",
      email_subject_user: `Your Valuation Request`,
      email_subject_admin: "Book a Valuation",
      email_server_func: "book_a_valuation",
      event_tracking: "book_a_valuation",
      page_url: "/contact"
    },
    {
      grpmd: "12",
      label: "Full Name*",
      placeholder: "Enter your full name here",
      name: "name",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      fieldClass: "",
      labelClass: "content_b-18"
    },
    {
      grpmd: "12",
      label: "Email Address*",
      placeholder: "Enter your email address here",
      name: "email",
      type: "email",
      element: "input",
      required: true,
      patternchk: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
      class: ""
    },
    {
      grpmd: "12",
      label: "Telephone Number*",
      placeholder: "Enter your phone number here",
      name: "telephone",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      fieldClass: "",
      labelClass: "content_b-18"
    },
    {
      element: "interest"
    },
    {
      step: "col-md-12",
      name: "terms",
      placeholder: 'I agree to the <a href="/terms-and-conditions" class="form-text-link">Terms & Conditions</a> and <a href="/privacy-policy" class="form-text-link"> Privacy Policy </a>. <br>',
      class: "mb-0 interest-radio over-60s-valuation col-md-12 terms-check",
      labelClass: "content_b-18",
      element: "checkbox",
      required: true
    },
    {
      name: "Book your valuation",
      type: "submit",
      element: "button",
      value: "submit",
      step: "col-md-12 text-center",
      class: "btn-secondary",
      formclass: ""
    },
    {
      element: "captcha",
      class: "py-2 captcha-class",
      captchaRef: recaptchaRef
    },
  ]);

  const handlechange = event => {
    // remove initial empty spaces 
    event.target.value = event.target.value.trimStart()
  }



  useEffect(() => {
    getIpAddress((ip) => {
      setIpAddress(ip)
    })
    if (token !== '') {

      const processFromData = async () => {


        formvalues['g-recaptcha-response'] = token;
        const page_url = typeof window !== 'undefined' ? window.location.href : ''

        var gtm_client_id = '';
        var user_agent = '';
        if (typeof window !== 'undefined') {
          user_agent = window.navigator.userAgent
          if (typeof window.ga !== 'undefined') {
            gtm_client_id = window.ga.getAll()[0].get('clientId');

          }
        }

        let formData = new FormData();
        formvalues['name'] = formvalues.name;
        formvalues['email_subject_user'] = fields[0].email_subject_user;
        formvalues['email_subject_admin'] = fields[0].email_subject_admin;
        formvalues['referrer'] = page_url;
        formvalues['google_analytics_client_id'] = gtm_client_id;
        formvalues['user_agent'] = user_agent;
        formvalues['ip_address'] = ipAddress;
        delete formvalues["address"]
        formvalues['extra'] = JSON.stringify(formvalues);
        formvalues['address'] = {
          "stno": props.home_stno,
          "street": props.home_street,
          "district": props.home_district,
          "post_town": props.home_post_town,
          "county": props.home_county,
          "postcode": props.home_postcode
        }
        formData.append('data', JSON.stringify(formvalues));

        postFormData(formData).then(async apiRes => {
          window.grecaptcha.reset()
          // lets send mail

          await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/functions`, {
            method: `POST`,
            mode: "no-cors",
            headers: {
              'Access-Control-Allow-Origin': '*',
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify(formvalues),
          })


          if (apiRes.success === true) {
            setFinalResponse(true)
            props.homevisit()
          }
          // .then(res => res.json())
          // setServerResponse(response)


          // const axiosOptions_email = {
          //   url: '/api/server/' + fields[0].email_server_func,
          //   method: "post",
          //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
          //   data: qs.stringify(formvalues),
          // }

          // axios(axiosOptions_email)
          //   .then(response => {
          //     console.log('mail sent!')
          //   })
          //   .catch(err =>
          //     console.log(err)
          //   );
        });

        const url = typeof window !== 'undefined' ? window.location.href : ''
        var type = props.type;
        if (formvalues.sell == "yes" && formvalues.let == "yes") {
          type = "Sell and let"
        }
        // tracking event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmit',
          'formType': 'form-' + fields[0].event_tracking,
          'formId': 'form-' + fields[0].event_tracking,
          'formName': fields[0].formname + '' + type,
          'formLabel': fields[0].formname + '' + type
        });

        setShowerror(false);
        // props.homevisit()
        // setThankyou(true);
        // setTimeout(() => {
        // $(".alert-success").show().delay(4000).fadeOut();
        // props.homevisit()
        // }, 500)
        // myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    // console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      if(document.querySelector('#terms').checked) {
        $(".terms-check .check-mark").removeClass("error");
      }
      else {
        $(".terms-check .check-mark").addClass("error");
      }
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

    }
    else {
      $("#home-visit-but").attr('disabled',true)
      $("#home-visit-but").text('Please wait...')
      event.preventDefault();
      setShowerror(false);
      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;


      json['files'] = file;
      setFile(file);
      setFormvalues(json);

      recaptchaRef.current.execute();
      // setToken("aaa")
      setValidated(false);

      // reset form
      const form = event.target
      if (finalresponse) {
        form.reset();
      }

    }
  };

  const url = typeof window !== 'undefined' ? window.location.href : ''

  var cookieData = getAllCookie();

  return (
    <div>
      <div ref={myRef} />

      {showerror && <div className="alert alert-danger alert-error">
        <p>{fields[0].error_text}</p>
      </div>}

      {showthankyou && <div className="alert alert-success">
        <p>{fields[0].success_text}</p>
      </div>}


      <Form className="form home-visit" id="contact-form" name={fields[0].formname} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit}>
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="address" value={props.address} />

        {/* <input type="hidden" name="stno" value={props.home_stno} />
        <input type="hidden" name="street" value={props.home_street} />
        <input type="hidden" name="district" value={props.home_district} />
        <input type="hidden" name="post_town" value={props.home_post_town} />
        <input type="hidden" name="county" value={props.home_county} />
        <input type="hidden" name="postcode" value={props.home_postcode} /> */}

        <input type="hidden" name="type" value={props.type} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="to_email_id" value={props.to_email_id ? props.to_email_id : ''} />
        <input type="hidden" name="bot-field" />

        <input type="hidden" name="utm_source" value={cookieData.utm_source} />
        <input type="hidden" name="utm_campaign" value={cookieData.utm_campaign} />
        <input type="hidden" name="utm_term" value={cookieData.utm_term} />
        <input type="hidden" name="utm_content" value={cookieData.utm_content} />
        <input type="hidden" name="utm_medium" value={cookieData.utm_medium} />
        <input type="hidden" name="form_type_id" value={props.type == 'let' ? "14" : "5"} />
        <input type="hidden" name="form_page" value={url} />
        <input type="hidden" name="brand_id" value={process.env.GATSBY_STRAPI_BRAND_ID} />
        <div className="row">
          {fields.map((field, index) => {
            if ("input" === field.element) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  label={field.label}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                />
              );
            }
            if ("file" === field.element) {
              return (
                <FileField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  label={field.label}
                  type={field.type}
                  fieldClass={field.fieldClass}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  key={`${field.element}~${index}`}
                  accept={field.accept}
                  // handlechange={handleAttachment}
                  required={field.required}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  id={field.id}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  label={field.label}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  step={field.step}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <Form.Group>
                  <ButtonField
                    name={field.name}
                    fieldClass={field.class}
                    step={field.step}
                    id="home-visit-but"
                    formclass={field.formclass}
                    type={field.type}
                    value={field.value}
                    key={`${field.element}~${index}`}
                  />
                </Form.Group>
              );
            }
            if ("interest" === field.element) {
              return (
                <Form.Group className="mb-0">
                  <CustomRadio type={props.type} />
                </Form.Group>
              )
            }
          })
          }
        </div>
      </Form>
    </div>
  );
}


const HomeVisitFormPage = (props) => (
  <HomeVisitForm classone={props.classone} homevisit={props.homevisit} to_email_id={props.to_email_id} address={props.address} type={props.type} title={props.title} {...props} />
)

export default HomeVisitFormPage