import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import axios from "axios";
import LoadingImage from "../../../images/loading.svg"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import * as qs from "query-string"
import $ from "jquery"

import HomeVisit from "../../forms/home-visit-quick"
import { CONTACT_PAGE_URL, FIND_A_PROPERTY_PAGE_URL } from "../../common/site/constants"

const HomeTrackResult = (props) => {

  const [property_details, setPropertyData] = useState('');
  const [loading, setloading] = useState(true);
  const formvalues = [];

  formvalues['address'] = props.address;
  formvalues['postcode'] = props.postcode;
  formvalues['bedroom'] = props.bedroom;
  formvalues['ptype'] = props.ptype;

  useEffect(async () => {
    const home = await window.fetch(`${process.env.GATSBY_STRAPI_SRC}/hometrack`, {
      method: `POST`,
      body: JSON.stringify({ address: props.address, postcode: props.postcode, bedroom: props.bedroom, ptype: props.ptype }),
    })
    setloading(false)
    setPropertyData(await home.json());
    $("#track-result-home").hide()
    $("#track-result-head").show()
  }, []);

  if (loading) return (
    <div className="valuation-form home-track-results">
      <div className="form-wrapper loading-img">
        <img src={LoadingImage} alt="" />
      </div>
      <div className="home-visit">
        <h2>Book a Home Visit Valuation</h2>
        <h3>Simply tell us the type of valuation you need and we’ll be in touch.</h3>
        <div className="btn-wrapper">
          <Link href="#" className="btn btn-primary">I’d like to Sell</Link>
          <Link href="#" className="btn">I’d like to Let</Link>

        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <div className="valuation-form home-track-results" id="track-results">
        {property_details.propertyValuation ?
          <div className="form-wrapper">
            <div className="info-wrapper">
              <div className="sales-info">
                <Link href="#" className="icons">
                  <i className="icon-sale"></i>
                </Link>
                <h2>Sales Valuation</h2>
                <div className="range">
                  <div className="limit">Maximum Valuation</div>
                  <div className="price">£{property_details?.propertyValuation?.upperValue.toLocaleString()}</div>
                </div>
                <div className="range">
                  <div className="limit one">Average Valuation</div>
                  <div className="price">£{property_details?.propertyValuation?.value.toLocaleString()}</div>
                </div>
                <div className="range last">
                  <div className="limit one">Minimum Valuation</div>
                  <div className="price">£{property_details?.propertyValuation?.lowerValue.toLocaleString()}</div>
                </div>
              </div>
              <div className="rental-info">
                <Link href="#" className="icons">
                  <i className="icon-rent"></i>
                </Link>
                <h2>Rental Valuation</h2>
                <div className="range">
                  <div className="limit">Maximum Valuation</div>
                  <div className="price">£{property_details?.rentalValuation?.monthlyRentalUpperValue.toLocaleString()} p/m</div>
                </div>
                <div className="range">
                  <div className="limit one">Average Valuation</div>
                  <div className="price">£{property_details?.rentalValuation?.monthlyRentalValue.toLocaleString()} p/m</div>
                </div>
                <div className="range last">
                  <div className="limit one">Minimum Valuation</div>
                  <div className="price">£{property_details?.rentalValuation?.monthlyRentalLowerValue.toLocaleString()} p/m</div>
                </div>
              </div>
            </div>
          </div> :
          <div className="form-wrapper no-hometrack-results-found">
            <div className="info-wrapper">
              <p>Sorry, No results found</p>
            </div>
          </div>
        }

        <div className="home-visit">
          <h2>Book a Home Visit Valuation</h2>
          <h3>Simply tell us the type of valuation you need and we’ll be in touch.</h3>
          <div className="btn-wrapper row">
            <div className="col-md-6 mb-md-0 mb-3">
              <HomeVisit {...props} title="I’d like to Sell" />
            </div>
            <div className="col-md-6">
              <HomeVisit {...props} title="I’d like to Let" />
            </div>

            {/* <Link href="#" className="btn btn-primary">I’d like to Sell</Link> */}
            {/* <Link href="#" className="btn">I’d like to Let</Link> */}

          </div>
        </div>
      </div>
      <div id="track-result-home">
        <div className="instant-form home-visit-success-results" id="home-form">
          <Container>
            <Row>
              <Col lg={10}>
                <div className="head">
                  <span class="sm-text">Home visit valuation</span>
                  <h1>Thank You</h1>
                  <div className="back">Your request has been sent to our team and we will contact you shortly to arrange an appointment and explain the process in more detail.
                  </div>
                </div>
              </Col>
              <Col lg={8} className="main-banner">
                <div className="btn-wrap">
                  <Link to={CONTACT_PAGE_URL.alias} class="btn btn-secondary">
                    <span>For any enquiries</span>Contact Us
                  </Link>
                  <Link to={FIND_A_PROPERTY_PAGE_URL.alias} class="btn">
                    <span>Buying or Renting?</span>Find a Property
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default HomeTrackResult